import React from 'react'
import Errors from "./errors";
import Hint from "../hint";
import {_t} from '../../../../js/translation';
import IntlTelInput from "react-intl-tel-input";
import {classNames} from "../../tools/react.dom.helpers";

const PhoneField = (props) => (
    <div className={classNames((props.skipFormGroup ? '' : 'form-group form-group__input', props.errors && '--error'))}>
        {props.label &&
            <span className={"form-group__label-wrapper " + (props.hintText ? 'form-group__label--has-hint' : '')}>
                <label htmlFor={props.name} className={"form-group__label label"}>
                    {props.icon ? <i className={"form-group__label-icon fal " + (props.icon)}/> : ''}
                    {_t(props.label)}
                </label>
                {props.hintText ? <Hint title={props.hintTitle} text={props.hintText}/> : ''}
            </span>
        }
        <div className="form-group__input-wrapper">
            <IntlTelInput
                onPhoneNumberChange={(isValid, value, countryData, fullNumber) => {
                    if (countryData.iso2 === 'hk' && fullNumber.length === 14) {
                        isValid = true;
                    }
                    if (countryData.iso2 === 'sg' && fullNumber.length === 12) {
                        isValid = true;
                    }
                    if (countryData.iso2 === 'au' && fullNumber.length === 15) {
                        isValid = true;
                    }
                    if (countryData.iso2 === 'ci' && fullNumber.length === 14) {
                        isValid = true;
                    }

                    if (isValid && value) {
                        if (/[^+\d\s()-]/.test(value)) {
                            isValid = false;
                        }
                    }

                    if (!value && (props.hasOwnProperty('required') && !props.required)) {
                        isValid = true;
                    }

                    props.onChange(isValid, value, countryData, fullNumber);
                }}
                onSelectFlag={props.onSelectFlag}
                geoIpLookup={props.geoIpLookup}
                containerClassName={`intl-tel-input ${props.isRtl ? 'intl-tel-input-rtl' : ''}`}
                inputClassName={"form-group__input input form-control " + (props.errors ? 'is-invalid' : '')}
                fieldName={props.name}
                defaultValue={props.value}
                defaultCountry={props.country}
                fieldId={props.name}
                nationalMode={false}
                separateDialCode={props.separateDialCode}
                useMobileFullscreenDropdown={false}
                telInputProps={{required: props.required}}
            />

            {props.errors && <Errors errors={props.errors} customClass="harmonizely-error-basic form-group__input-error" />}
        </div>
    </div>
);

export default PhoneField;